<template>
  <v-container id="boletos_ferry" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-ticket-confirmation"
      inline
      :title="$t('tickets')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Boleto</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/ Editar Boletos Ferry-->
      <v-dialog max-width="600px" v-model="dialog">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Ruta -->
                <v-col cols="6">
                  <v-text-field
                    label="Ruta*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.ruta.$model"
                    :error-messages="rutaErrors"
                    @blur="$v.editedItem.ruta.$touch()"
                    @input="$v.editedItem.ruta.$reset(), (changed = true)"
                    @keypress="isLetter($event)"
                  ></v-text-field>
                </v-col>

                <!-- origen -->
                <v-col cols="6">
                  <v-text-field
                    label="Origen*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.origen.$model"
                    :error-messages="origenErrors"
                    @blur="$v.editedItem.origen.$touch()"
                    @input="$v.editedItem.origen.$reset(), (changed = true)"
                    @keypress="isLetter($event)"
                  ></v-text-field>
                </v-col>

                <!-- Tipo -->
                <v-col cols="12">
                  <v-select
                    dense
                    hint="Seleciona Tipo"
                    item-text="tipo"
                    item-value="tipo"
                    label="Tipo*"
                    persistent-hint
                    required
                    v-model="$v.editedItem.tipo.$model"
                    :error-messages="tipoErrors"
                    :items="tipos"
                    :menu-props="{ top: true, offsetY: true }"
                    @input="changed = true"
                  ></v-select>
                </v-col>

                <!-- Precio Sencillo-->
                <v-col cols="6">
                  <v-text-field
                    label="Precio Sencillo*"
                    hint="Registrar Precio"
                    maxlength="4"
                    required
                    v-model="$v.editedItem.precios.sencillo.$model"
                    :error-messages="precioSencilloErrors"
                    @blur="$v.editedItem.precios.sencillo.$touch()"
                    @input="$v.editedItem.precios.sencillo.$reset(), (changed = true)"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Precio Redondo-->
                <v-col cols="6">
                  <v-text-field
                    hint="Registrar Precio"
                    label="Precio Redondo*"
                    maxlength="4"
                    required
                    v-model="$v.editedItem.precios.redondo.$model"
                    :error-messages="precioRedondoErrors"
                    @blur="$v.editedItem.precios.redondo.$touch()"
                    @input="$v.editedItem.precios.redondo.$reset(), (changed = true)"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Fecha Inicio Validez -->
                <v-col cols="6">
                  <v-menu
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                    v-model="fromDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Válido desde"
                        prepend-icon="mdi-calendar-month"
                        v-bind="attrs"
                        v-model="editedItem.desde"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="editedItem.desde"
                      :min="minDate"
                      :max="maxDateDisp"
                      @input=";(fromDateMenu = false), (changed = true)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Fecha Final Validez -->
                <v-col cols="6">
                  <v-menu
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                    v-model="toDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Válido hasta"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-model="editedItem.hasta"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.hasta"
                      :min="minDate"
                      :max="maxDateDisp"
                      @input="toDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>

            <small>*Campo obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de boletos del ferry -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="boletos"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de Fecha Inicio Validez -->
        <template v-slot:[`item.desde`]="{ item }">
          <DateFormat :begin="item.desde" :end="item.hasta" />
        </template>

        <!-- Slot de Precios -->
        <template v-slot:[`item.precios`]="{ item }">
          <TwoFieldsOneColumn
            title1="Sencillo"
            title2="Redondo"
            :line1="item.precios.sencillo"
            :line2="item.precios.redondo"
          />
        </template>

        <!-- Slot de Acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>

        <template v-slot:no-data>¡No hay datos para mostrar!</template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter fileName="BoletosFerry" :dataArray="boletos" :dataFields="fields" />
    </base-material-card>
  </v-container>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import DateFormat from '@/components/general/DateFormat.vue'
import Status from '@/components/general/StatusInfo.vue'
import TwoFieldsOneColumn from '@/components/general/TwoFieldsOneColumn.vue'

export default {
  name: 'CatalogoBoletosFerry',
  components: {
    ConfirmationDialog,
    DataExporter,
    DateFormat,
    Status,
    TwoFieldsOneColumn,
  },
  data: () => ({
    // Variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    fromDateMenu: false,
    isEditing: false,
    isLoading: false,
    isSure: false,
    maxDate: '2021-01-31',
    minDate: new Date().toISOString().substr(0, 10),
    overlay: false,
    search: undefined,
    toDateMenu: false,
    // Arreglos
    boletos: [],
    headers: [
      { align: 'start', sortable: false, text: 'Ruta', value: 'ruta' },
      { align: 'start', sortable: false, text: 'Origen', value: 'origen' },
      { align: 'start', sortable: false, text: 'Tipo', value: 'tipo' },
      { text: 'Precios', value: 'precios' },
      { text: 'Vigencia', value: 'desde' },
      { text: 'Activo', value: 'activo' },
      { sortable: false, text: 'Acciones', value: 'actions' },
    ],
    tipos: ['Adulto', 'Niño'],
    value: [],
    // Objetos
    defaultItem: {
      _id: '',
      ruta: '',
      origen: '',
      tipo: '',
      desde: null,
      hasta: null,
      precios: {
        sencillo: '',
        redondo: '',
      },
      activo: true,
    },
    editedItem: {
      _id: '',
      ruta: '',
      origen: '',
      tipo: '',
      desde: null,
      hasta: null,
      precios: {
        sencillo: '',
        redondo: '',
      },
      activo: true,
    },
    fields: {
      Ruta: 'ruta',
      Origen: 'origen',
      Tipo: 'tipo',
      Precio_Sencillo: 'precios.sencillo',
      Precio_Redondo: 'precios.redondo',
      Desde: 'desde',
      Hasta: 'hasta',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      precios: {
        sencillo: { required, minLength: minLength(1), maxLength: maxLength(4) },
        redondo: { required, minLength: minLength(1), maxLength: maxLength(4) },
      },
      ruta: { required, minLength: minLength(1), maxLength: maxLength(50) },
      origen: { required, minLength: minLength(1), maxLength: maxLength(50) },
      tipo: { required, minLength: minLength(1), maxLength: maxLength(10) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Boleto' : 'Editar Boleto'
    },

    //Formato para campo inicio vigencia
    fromDateDisp() {
      return this.convierteNumeroFecha(this.editedItem.desde)
    },

    /**
     * @name maxDateDisp
     *
     * @description Calcula la fecha máxima para poder realizar una reserva
     * en este caso en particular un año posterior a la fecha de la reserva
     *
     * @return {date} fecha un año posterior a la fecha actual
     */
    maxDateDisp() {
      const date = new Date() //.toISOString().substr(0, 10)
      const year = date.getFullYear() + 1
      const month = date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + day
    },

    // Propiedad computada para errores en campo Origen
    origenErrors() {
      const errors = []
      if (!this.$v.editedItem.origen.$dirty) return errors
      !this.$v.editedItem.origen.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.origen.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.origen.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo precio redondo
    precioRedondoErrors() {
      const errors = []
      if (!this.$v.editedItem.precios.redondo.$dirty) return errors
      !this.$v.editedItem.precios.redondo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.precios.redondo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.precios.redondo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo precio sencillo
    precioSencilloErrors() {
      const errors = []
      if (!this.$v.editedItem.precios.sencillo.$dirty) return errors
      !this.$v.editedItem.precios.sencillo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.precios.sencillo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.precios.sencillo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo Ruta
    rutaErrors() {
      const errors = []
      if (!this.$v.editedItem.ruta.$dirty) return errors
      !this.$v.editedItem.ruta.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.ruta.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.ruta.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo tipo
    tipoErrors() {
      const errors = []
      if (!this.$v.editedItem.tipo.$dirty) return errors
      !this.$v.editedItem.tipo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.tipo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 4 caracteres.')
      !this.$v.editedItem.tipo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 4 caracteres.')
      return errors
    },

    //Formato para campo fin vigencia
    toDateDisp() {
      return this.convierteNumeroFecha(this.editedItem.hasta)
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerBoletos()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let payload = {}
      payload.id = this.editedItem._id
      this.$store
        .dispatch('ferryTicket/deleteFerryTicket', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de boletos de ferry
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * boletos de ferry
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de boletos de ferry
     */
    close(checkbox) {
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      this.isSure = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.defaultItem.precios.sencillo = ''
        this.defaultItem.precios.redondo = ''
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Convierte fecha a número
     */
    convierteFechaNumero(date) {
      const fecha = new Date(date).toISOString().substring(0, 10)

      let year = fecha.slice(0, 4)
      let month = fecha.slice(5, 7)
      let day = fecha.slice(8, 10)

      return parseFloat(year + month + day)
    },

    /**
     * @description Convierte número a fecha
     */
    convierteNumeroFecha(date) {
      const cadena = date.toString()

      let year = cadena.slice(0, 4)
      let month = cadena.slice(4, 6)
      let day = cadena.slice(6, 8)

      const fecha = year + '-' + month + '-' + day

      return fecha
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.boletos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.boletos.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar boletos de ferry
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.boletos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.desde = this.convierteNumeroFecha(this.boletos[this.editedIndex].desde)
      this.editedItem.hasta = this.convierteNumeroFecha(this.boletos[this.editedIndex].hasta)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter para saber si es una letra
     */
    isLetter: function (e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\u00C0-\u00FF-, ]*$/.test(char)) return true
      else e.preventDefault()
    },

    /**
     * @description Evalua el caracter para saber si es un número
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Obtiene un listado de todos los boletos de ferry
     */
    obtenerBoletos() {
      let payload = {}
      this.$store
        .dispatch('ferryTicket/fetchFerryTicket', payload)
        .then((response) => {
          this.boletos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un boleto de ferry nuevo o
     * la edicion de un boleto de ferry
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const fechaDesdeConvertida = this.convierteFechaNumero(this.editedItem.desde)
        const fechaHastaConvertida = this.convierteFechaNumero(this.editedItem.hasta)

        let body = {
          ruta: this.editedItem.ruta,
          origen: this.editedItem.origen,
          tipo: this.editedItem.tipo,
          precios: {
            sencillo: this.editedItem.precios.sencillo,
            redondo: this.editedItem.precios.redondo,
          },
          desde: fechaDesdeConvertida,
          hasta: fechaHastaConvertida,
        }

        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('ferryTicket/editFerryTicket', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.boletos[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('ferryTicket/addFerryTicket', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.boletos.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
